<template>
  <div>
     <div class="flex jc-b">
          <div class="cash-box flex">
            <p>保证金：{{ coachInfo.bond || 0 }}</p>
            <p class="m-l20">可提现：{{ coachInfo.withdrawable_bond || 0}}</p>
          </div>
          <el-button type="warning" v-permission="'subCoachBond'" @click="openBond">扣除保证金</el-button>
    </div>
        <auto-table
        :DataList="DataList"
        :option="Option"
        :total="Total"
        @changePage="getList"
        >
        </auto-table>
         <!-- 扣除保证金 -->
    <el-dialog title="扣除保证金" :visible.sync="bondVisible" width="30%">
      <div>
        <el-form ref="form" label-width="80px">
          <el-form-item label="扣除金额">
            <el-input v-model="bondAmount"></el-input>
          </el-form-item>
          <el-form-item label="扣除理由">
            <el-input type="textarea" v-model="refuse"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="bondVisible = false">取 消</el-button>
        <el-button type="primary" @click="subBond">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
 
  data() {
    return {
      DataList: [],
      Option: [
        { name: "名称", value: "title" },
        { name: "保证金", value: "amount" },
        { name: "备注", value: "remark" },
        { name: "添加时间", value: "create_time" },
      ],
      Total: 0,
      coach_id: '',
      bondVisible: false,
      bondAmount:'',
      refuse:'',
      coachInfo:{}
    };
  },

  mounted() {
    this.coach_id = this.$route.query.id 
  },

  methods: {
    init(){
        if(this.coach_id && this.DataList.length == 0){
            this.getCoach()
            this.getList(1);
        }
    },
    //教练信息
    getCoach() {
      let url = "/user/coach/findById";
      let params = {
        id: this.coach_id,
      };
      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
           this.coachInfo = res.data.data;
        });
    },
    // 扣除保证金
    subBond() {
      if (this.bondAmount <= 0) {
        this.$message({ message: "请填写金额" });
        return;
      }
      let url = "/user/coach/updateBond";
      let data = {
        id: this.coach_id,
        type: 2,
        bond: "-" + this.bondAmount,
        remark: this.refuse,
      };
      this.$axios({
        url,
        data,
        method: "post",
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message({ message: "扣除成功" });
          this.getCoach();
          this.getList(1);
          this.bondVisible = false;
        }
      });
    },
    openBond() {
      this.bondVisible = true;
    },
     //保证金记录
    getList(v) {
      let page = v || 1
      let url = "/user/coachBondRecord/queryManagerListPage";
      let params = {
        pageSize: 10,
        currentPage: page,
        coach_id: this.coach_id,
      };
      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          this.DataList = res.data.data.rows;
          this.Total = res.data.data.total;
        });
    },
  },
};
</script>